/* eslint-disable quotes */
import { Stack, SxProps, Typography } from '@mui/material';
import { useMemo } from 'react';

const CHIP: (bgColor: string, textColor: string) => SxProps = (
  bgColor,
  textColor,
) => ({
  bgcolor: bgColor,
  color: textColor,
  borderRadius: '6px',
  height: 28,
  minWidth: 55,
  paddingInline: 0.8,
  maxWidth: '100%',
  boxSizing: 'border-box',
});

const TEXT: SxProps = {
  fontSize: 11,
  letterSpacing: '-0.3 px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: '100%',
  fontWeight: 500,
};

interface Props {
  type: 'Completed' | 'Did not run';
}

export default function HistoryScanStatusChip({ type }: Props) {
  const statusTitle = useMemo(() => {
    switch (type) {
      default:
        return type;
    }
  }, [type]);

  const textColor = useMemo(() => {
    switch (type) {
      case 'Did not run':
        return '#F82F02';

      default:
        return '#0F8F44';
    }
  }, [type]);

  const bgColor = useMemo(() => {
    switch (type) {
      case 'Did not run':
        return '#E2432930';

      default:
        return '#0FCE5C4D';
    }
  }, [status]);

  return (
    <Stack
      sx={CHIP(bgColor, textColor)}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Typography variant="body1" sx={TEXT}>
        {statusTitle === 'Did not run' ? "Didn't run" : 'Complete'}
      </Typography>
    </Stack>
  );
}
