import { ContentCopy } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Stack,
  SxProps,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ExportIcon2 } from '../assets/svgs/icons/ExportIcon2';
import { ScanIcon } from '../assets/svgs/icons/ScanIcon';
import APICatalogGrid from '../components/APICatalog/APICatalogGrid';
import ApplicationOverviewCard from '../components/Application/ApplicationOverviewCard';
import ApplicationScanOverviewCard from '../components/Application/ApplicationScanOverviewCard';
import SelectVulnerabilityStatus from '../components/Common/SelectVulnerabilityStatus';
import RunScanDrawer from '../components/RunScanDrawer/RunScanDrawer';
import ScanHistoryGrid from '../components/ScansHistory/ScanHistoryGrid';
import EditSrouceDrawer from '../components/Setup/EditSource/EditSourceDrawer';
import TestCasesGrid from '../components/Vulnerabilities/TestCasesGrid';
import VulnerabilitiesGrid from '../components/Vulnerabilities/VulnerabilitiesGrid';
import {
  ApplicationContext,
  ApplicationContextProps,
} from '../contexts/Application';
import { OnboardContext } from '../contexts/Onboard';
import {
  PentestReportDownloaderContext,
  PentestReportDownloaderContextProps,
} from '../contexts/PentestReportDownloaderContext';
import { SnackbarContext } from '../contexts/Snackbar';
import { APISource } from '../contexts/User';
import { useHasFeature } from '../hooks/useHasFeature';
import { track } from '../utils/analytics';
import { usePersistedState } from '../hooks/usePersistedState';

const TAB_PANEL: SxProps = {
  width: '100%',
  minHeight: 150,
  border: 'solid 1px #D3D3D3',
  boxShadow: '0px 0px 4px 0px #0000001A',
  borderRadius: 1,
};

const TabPanel = ({ children }: { children?: ReactNode }) => {
  return <Box sx={TAB_PANEL}>{children}</Box>;
};

const ComingSoon = () => {
  return (
    <Stack height={'20vh'} justifyContent={'center'}>
      <Typography variant="h3" color={'gray'}>
        Coming Soon
      </Typography>
    </Stack>
  );
};

const Application = () => {
  const { show } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const { setState: setOnboardState } = useContext(OnboardContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const { setApplicationId, application, isLoadingApplication } = useContext(
    ApplicationContext,
  ) as ApplicationContextProps;

  const { downloading, request, downloadReport } = useContext(
    PentestReportDownloaderContext,
  ) as PentestReportDownloaderContextProps;

  const hasDownloadPentestReportFeature = useHasFeature(
    'download_pentest_report',
  );

  const [isEditSrouceDrawerOpen, setIsEditSrouceDrawerOpen] =
    useState<string>();
  const [selectedSource, setSelectedSource] = useState<APISource>();
  const [isRunScanDrawerOpen, setIsRunScanDrawerOpen] = useState(false);

  useEffect(() => {
    setApplicationId(id);
  }, [id]);

  useEffect(() => {
    if (!application) return;
    if (searchParams.get('tour') !== 'true') return;
    setOnboardState((s) => {
      return {
        ...s,
        stepIndex: 0,
        run: true,
        nonce: Date.now() + '',
        steps: [
          {
            target: '#app-documentation-sources',
            content: (
              <Stack alignItems={'stretch'}>
                <Box paddingX={2} paddingY={1}>
                  <Typography variant="subtitle1" textAlign="start">
                    Add your first documentation source
                  </Typography>
                </Box>
                <Divider variant="fullWidth" />
                <Stack paddingX={2} paddingY={1} spacing={2}>
                  <Typography textAlign={'start'}>
                    Integrate existing API documentation from Swagger, Postman,
                    or OpenAPI files to Pynt for automatic import and analysis
                    of API endpoints.
                  </Typography>
                  <Stack
                    direction={'row'}
                    justifyContent={'flex-start'}
                    spacing={1}
                  >
                    <Button
                      variant="text"
                      onClick={() => {
                        track(
                          'web_app_create_inventory_onboard_skip_button_click',
                          { step: 1 },
                        );
                        setSearchParams((s) => {
                          const newSearchParams = new URLSearchParams(s);
                          newSearchParams.delete('tour');
                          return newSearchParams;
                        });
                        setOnboardState((s) => ({
                          ...s,
                          run: false,
                          stepIndex: 0,
                        }));
                      }}
                    >
                      Skip
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            ),
            handleTargetClick: () => {
              setOnboardState((s) => ({
                ...s,
                run: false,
              }));
            },
            disableScrolling: true,
            disableBeacon: true,
            hideCloseButton: true,
            disableOverlayClose: true,
            spotlightClicks: true,
            hideFooter: true,
            styles: {
              tooltip: { padding: 0 },
              tooltipContent: { padding: 0 },
            },
          },
          {
            target: '#app-production-sources',
            content: (
              <Stack alignItems={'stretch'}>
                <Box paddingX={2} paddingY={1}>
                  <Typography variant="subtitle1" textAlign="start">
                    Add your first production source
                  </Typography>
                </Box>
                <Divider variant="fullWidth" />
                <Stack paddingX={2} paddingY={1} spacing={2}>
                  <Typography textAlign={'start'}>
                    Integrate with popular API gateway providers like AWS API
                    Gateway, Kong, or Azure API Management to streamline API
                    discovery.
                  </Typography>
                  <Stack
                    direction={'row'}
                    justifyContent={'flex-start'}
                    spacing={1}
                  >
                    <Button
                      variant="text"
                      onClick={() => {
                        track(
                          'web_app_create_inventory_onboard_skip_button_click',
                          { step: 2 },
                        );
                        setSearchParams((s) => {
                          const newSearchParams = new URLSearchParams(s);
                          newSearchParams.delete('tour');
                          return newSearchParams;
                        });
                        setOnboardState((s) => ({
                          ...s,
                          run: false,
                          stepIndex: 0,
                        }));
                      }}
                    >
                      Skip
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            ),
            showSkipButton: true,
            handleTargetClick: () => {
              setOnboardState((s) => ({
                ...s,
                stepIndex: 1,
                run: false,
              }));
            },
            disableScrolling: true,
            disableBeacon: true,
            hideCloseButton: true,
            disableOverlayClose: true,
            spotlightClicks: true,
            hideFooter: true,
            styles: {
              tooltip: { padding: 0 },
              tooltipContent: { padding: 0 },
            },
          },
        ],
      };
    });
  }, [application, searchParams]);

  const onAppIdCopyButtonClick = useMemo(() => {
    return (app: any) => {
      track('web_app_copy_application_id_button_click');

      navigator.clipboard.writeText(app.app_id);
      show('Application ID copied to clipboard');
    };
  }, []);

  const tabs = useMemo(
    () => [
      { id: 'apis', name: 'APIs' },
      { id: 'vulnerabilities', name: 'Vulnerabilities' },
      { id: 'tests', name: 'Tests' },
      { id: 'scans', name: 'Scans history' },
      { id: 'logs', name: 'Logs' },
    ],
    [],
  );

  const selectedTab = useMemo(() => {
    const tab = searchParams.get('tab');
    if (tabs.find((t) => t.id === tab)) return tab;
    return 'apis';
  }, [searchParams, tabs]);

  const onNewSourceButtonClick = useMemo(() => {
    return (source?: 'documentation' | 'testing' | 'production') => {
      if (source === 'testing') {
        track('web_app_add_new_testing_source_button_click');

        setIsRunScanDrawerOpen(true);
        return;
      }
      track('web_app_add_new_source_button_click');

      setSelectedSource(undefined);
      setIsEditSrouceDrawerOpen(source);
    };
  }, []);

  const onViewSourceButtonClick = useMemo(() => {
    return (source: APISource) => {
      track('web_app_view_source_button_click');

      if (source.type === 'scan') {
        return;
      }

      setSelectedSource(source);
      setIsEditSrouceDrawerOpen('true');
    };
  }, []);

  return (
    <>
      <EditSrouceDrawer
        mode={application && selectedSource ? 'view' : 'create'}
        application={application}
        sourceId={selectedSource?.source_id}
        defaultData={
          selectedSource?.source_id
            ? undefined
            : { sourceOrigin: isEditSrouceDrawerOpen as any }
        }
        isOpen={!!isEditSrouceDrawerOpen}
        setIsOpen={() => setIsEditSrouceDrawerOpen(undefined)}
        onSave={(data) => {
          if (data.sourceOrigin !== 'documentation') return;

          setSearchParams((s) => {
            const a = new URLSearchParams(s);
            a.delete('tour');
            return a;
          });
          setOnboardState((s) => ({
            ...s,
            stepIndex: 1,
            run: false,
            nonce: Date.now() + '',
          }));
          setTimeout(() => {
            setOnboardState((s) => ({
              ...s,
              stepIndex: 1,
              run: true,
              nonce: Date.now() + '',
            }));
          }, 500);
        }}
      />
      <RunScanDrawer
        hideAppSelector
        application={isRunScanDrawerOpen ? application : undefined}
        isOpen={isRunScanDrawerOpen}
        setIsOpen={() => setIsRunScanDrawerOpen(false)}
      />
      {isLoadingApplication || !application ? (
        <Container maxWidth={false}>
          <CircularProgress sx={{ mt: 12 }} />
        </Container>
      ) : (
        <Container maxWidth={false} key={application.app_id}>
          <Stack
            sx={{ width: '100%' }}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            spacing={3}
          >
            <Stack
              direction={'row'}
              justifyContent={'start'}
              alignItems={'center'}
              spacing={2}
            >
              <Button
                variant="text"
                size="small"
                onClick={() => navigate('/dashboard/applications')}
              >
                <Typography sx={{ color: '#7B7B88' }}>
                  {'All applications >'}
                </Typography>
              </Button>
              <Typography
                sx={{ fontSize: 24, fontWeight: 500, color: '#39394B' }}
              >
                {application?.name}
              </Typography>
            </Stack>
            <Stack
              direction={'row'}
              justifyContent={'start'}
              alignItems={'center'}
              spacing={2}
            >
              {false ? (
                <Button
                  variant="outlined"
                  sx={{ borderColor: 'black', color: 'black' }}
                  startIcon={<ContentCopy />}
                  onClick={() => onAppIdCopyButtonClick(application)}
                >
                  Copy App ID
                </Button>
              ) : (
                <></>
              )}
              <Button
                variant="contained"
                sx={{ bgcolor: 'black', color: 'white' }}
                startIcon={<ScanIcon color="white" />}
                onClick={() => {
                  setIsRunScanDrawerOpen(true);
                }}
              >
                New scan
              </Button>
              {application && hasDownloadPentestReportFeature ? (
                <Tooltip
                  title={'A report is being created for a different app'}
                  disableFocusListener={
                    !downloading || request?.appId === application?.app_id
                  }
                  disableHoverListener={
                    !downloading || request?.appId === application?.app_id
                  }
                  disableTouchListener={
                    !downloading || request?.appId === application?.app_id
                  }
                >
                  <span>
                    <LoadingButton
                      variant="contained"
                      sx={{ bgcolor: 'black', color: 'white' }}
                      startIcon={<ExportIcon2 color="white" />}
                      disabled={
                        downloading || !application.data_sources.scan?.source_id
                      }
                      loading={
                        downloading && request?.appId === application?.app_id
                      }
                      onClick={() => {
                        track(
                          'application_screen_create_pentest_report_button_click',
                          { appId: application.app_id },
                        );
                        downloadReport(application.app_id);
                      }}
                    >
                      Create Pentest Report
                    </LoadingButton>
                  </span>
                </Tooltip>
              ) : (
                <></>
              )}
              {/* <Button
                variant="contained"
                sx={{ bgcolor: 'black', color: 'white' }}
                size="large"
                startIcon={<InfoIcon color="white" />}
              >
                Detail
              </Button> */}
            </Stack>
          </Stack>
          <Grid container spacing={3} pt={3}>
            <Grid item xs={12} xl={9}>
              <ApplicationOverviewCard
                onViewSource={onViewSourceButtonClick}
                onAddSource={onNewSourceButtonClick}
              />
            </Grid>
            <Grid item xs={12} xl={3}>
              <ApplicationScanOverviewCard />
            </Grid>
          </Grid>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 4, mb: 2 }}>
            <Tabs
              value={selectedTab}
              onChange={(_, t) => {
                if (selectedTab === t) return;
                setSearchParams((curr) => {
                  const newSP = new URLSearchParams(curr);
                  newSP.set('tab', t);
                  return newSP;
                });
              }}
              aria-label="lab API tabs example"
            >
              {tabs.map((tab) => (
                <Tab key={tab.id} label={tab.name} value={tab.id} />
              ))}
            </Tabs>
          </Box>
          {selectedTab === 'apis' ? (
            <TabPanel>
              <APICatalogGrid
                id={`APICatalogGridApplicationScreen-${id ?? ''}`}
                autoHeight={true}
                autoPageSize={false}
                hideFooter={false}
                applicationFilter={false}
                sorting={[{ field: 'properties.scan_risk', sort: 'desc' }]}
                pyntFilter={{ application: id ?? '' }}
                columnVisibilityModel={{
                  application: false,
                }}
              />
            </TabPanel>
          ) : (
            <></>
          )}
          {selectedTab === 'vulnerabilities' ? (
            <TabPanel>
              <AppVulnerabilitiesTab />
            </TabPanel>
          ) : (
            <></>
          )}
          {selectedTab === 'tests' ? (
            <TabPanel>
              <TestCasesGrid
                autoHeight={true}
                autoPageSize={false}
                hideFooter={false}
              />
            </TabPanel>
          ) : (
            <></>
          )}
          {selectedTab === 'scans' ? (
            <TabPanel>
              <ScanHistoryGrid
                id={`ScanHistoryGridApplicationScreen-${id ?? ''}`}
                autoHeight={true}
                autoPageSize={false}
                hideFooter={false}
                showOpenReportButton
                showDownloadRequestsLogButton
                applicationFilter={false}
                pyntFilter={{ application: id ?? '' }}
                filter={{
                  items: [
                    {
                      id: 1,
                      field: 'application',
                      operator: 'is',
                      value: id,
                    },
                  ],
                }}
              />
            </TabPanel>
          ) : (
            <></>
          )}
          {selectedTab === 'logs' ? (
            <TabPanel>
              <ComingSoon />
            </TabPanel>
          ) : (
            <></>
          )}
        </Container>
      )}
    </>
  );
};

const AppVulnerabilitiesTab = () => {
  const { application } = useContext(
    ApplicationContext,
  ) as ApplicationContextProps;

  const [selectedVulnerabilityStatus, setSelectedVulnerabilityStatus] =
    usePersistedState('pynt-selectedVulnerabilityStatus', '*');

  const getPyntFilter = useCallback(() => {
    let pyntFilter: any = { application_id: application?.app_id };
    if (selectedVulnerabilityStatus && selectedVulnerabilityStatus !== '*') {
      pyntFilter = { ...pyntFilter, status: selectedVulnerabilityStatus };
    }
    return pyntFilter;
  }, [application, selectedVulnerabilityStatus]);

  return (
    <Box>
      <Stack
        direction={'row'}
        sx={{ bgcolor: 'white', p: 2, borderRadius: 1, pb: 0 }}
        justifyContent={'start'}
        alignItems={'center'}
        spacing={2}
      >
        <Typography
          sx={{
            flexGrow: 1,
            textAlign: 'start',
            fontWeight: 500,
            fontSize: 18,
          }}
        >
          Vulnerabilities
        </Typography>
        <SelectVulnerabilityStatus
          selectedVulnerabilityStatus={selectedVulnerabilityStatus}
          setSelectedVulnerabilityStatus={setSelectedVulnerabilityStatus}
        />
      </Stack>
      <VulnerabilitiesGrid
        id={`AppVulnerabilitiesTab-${application?.app_id}`}
        applicationId={application?.app_id}
        pyntFilter={getPyntFilter()}
        autoHeight={true}
        autoPageSize={false}
        hideFooter={false}
        columnVisibilityModel={{ application_id: false }}
      />
    </Box>
  );
};

export default Application;
