import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { track } from '../../utils/analytics';
import TextFieldTitle from '../Common/TextFieldTitle';
import React, { useContext, useState } from 'react';
import { uploadFile } from '../../services/FilesService';
import { runHarScanOnCloud } from '../../services/ScansService';
import { CloudHarRunScanData, RunScanData } from './RunScanDrawer';
import { SnackbarContext } from '../../contexts/Snackbar';

interface Props {
  data: CloudHarRunScanData;
  setData:
    | ((newData: RunScanData) => void)
    | ((updateCb: (current: RunScanData) => RunScanData) => void);
  onDone?: () => void;
  setDisabled?: (disabled: boolean) => void;
}

const HarUploadScanWizard = ({ data, onDone }: Props) => {
  const { show } = useContext(SnackbarContext);

  const [harFile, setHarFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    track('web_app_run_scan_drawer_postman_submit_har_file_button_click');
    const selectedFile = event.target.files?.[0] || null;
    setHarFile(selectedFile);
  };

  const handleNextClick = async () => {
    if (!harFile) throw new Error('Missing har file');
    if (!data.appId) throw new Error('Missing app id');

    try {
      setIsLoading(true);

      const { bucketName, path } = await uploadFile(
        harFile,
        'har-files',
        String(new Date().getTime()),
      );

      await runHarScanOnCloud(data.appId || '', bucketName, path);

      setIsLoading(false);

      show('Scan started successfully', 'success');

      onDone?.();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Stack alignItems={'start'} gap={0.25}>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Box>
            <TextFieldTitle title="Upload File" />
            <Stack direction={'row'} gap={2} alignItems={'center'}>
              <Button variant="contained" component="label">
                Upload File
                <input type="file" hidden onChange={handleFile} />
              </Button>
              {harFile ? (
                <Typography>
                  {typeof harFile === 'object' ? harFile.name : harFile}
                </Typography>
              ) : (
                <></>
              )}
            </Stack>
          </Box>
        </Stack>
      </Stack>
      <Stack direction={'row'} justifyContent={'end'}>
        <Box flex={1} />
        <LoadingButton
          variant="contained"
          size="large"
          loading={isLoading}
          disabled={!harFile}
          onClick={() => {
            track(
              'web_app_run_scan_drawer_postman_submit_har_file_button_click',
            );
            handleNextClick();
          }}
        >
          Next
        </LoadingButton>
      </Stack>
    </>
  );
};

export default HarUploadScanWizard;
