import { SxProps, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { Theme } from '@nivo/core';
import { ReactNode, useContext, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AppsIcon } from '../../assets/svgs/icons/AppsIcon';
import { CatalogIcon } from '../../assets/svgs/icons/CatalogIcon';
import { OverviewIcon } from '../../assets/svgs/icons/OverviewIcon';
import { ScanIcon } from '../../assets/svgs/icons/ScanIcon';
import { SettingsIcon } from '../../assets/svgs/icons/SettingsIcon';
import { VulnerabilityIcon } from '../../assets/svgs/icons/VulnerabilityIcon';
import { AuthContext } from '../../contexts/Auth';
import { usePersistedJsonState } from '../../hooks/usePersistedState';
import PlanManagement from '../Users/PlanManagement';
import DashboardDrawer, {
  drawerWidth,
  miniDrawerWidth,
} from './DashboardDrawer';

const CONTENT = (open: boolean, theme: Theme | any): SxProps => ({
  bgcolor: 'background.default',
  p: 3,
  width: `calc(100% - ${open ? drawerWidth : miniDrawerWidth}px)`,
  ml: `${open ? drawerWidth : miniDrawerWidth}px`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

export interface MenuItem {
  id: string;
  name: string;
  path: string;
  icon: ReactNode;
  actions?: ReactNode;
  hide?: boolean;
  active?: boolean;
}

export default function DashboardLayout() {
  const location = useLocation();
  const theme = useTheme();
  const { isProd } = useContext(AuthContext);
  const [openDrawer, setOpenDrawer] = usePersistedJsonState('openDrawer', true);

  const menuItems = useMemo(() => {
    const items: MenuItem[] = [
      {
        id: 'overview',
        name: 'Dashboard',
        path: '/dashboard/overview',
        icon: <OverviewIcon />,
      },
      // {
      //   id: 'setup',
      //   name: 'Setup',
      //   path: '/dashboard/setup',
      //   icon: <SetupIcon />,
      // },
      {
        id: 'applications',
        name: 'Applications',
        path: '/dashboard/applications',
        icon: <AppsIcon />,
      },
      {
        id: 'api-catalog',
        name: 'APIs',
        path: '/dashboard/api-catalog',
        icon: <CatalogIcon />,
      },
      {
        id: 'scans-history',
        name: 'Scans History',
        path: '/dashboard/scans-history',
        icon: <ScanIcon />,
      },
      {
        id: 'vulnerabilities',
        name: 'Vulnerabilities',
        path: '/dashboard/vulnerabilities',
        icon: <VulnerabilityIcon />,
      },
    ];

    return items.map((item) => ({
      ...item,
      active: location.pathname.includes(item.path),
    }));
  }, [isProd, location.pathname]);

  const bottomMenuItems = useMemo(() => {
    const items: MenuItem[] = [
      {
        id: 'settings',
        name: 'Settings',
        path: '/dashboard/settings',
        icon: <SettingsIcon />,
      },
    ];

    return items.map((item) => ({
      ...item,
      active: location.pathname.includes(item.path),
    }));
  }, [location.pathname]);

  const nonHiddenMenuItems: MenuItem[] = useMemo(
    () => menuItems.filter((a) => !a.hide),
    [menuItems],
  );

  const nonHiddenBottomMenuItems: MenuItem[] = useMemo(
    () => bottomMenuItems.filter((a) => !a.hide),
    [bottomMenuItems],
  );

  return (
    <>
      <PlanManagement />
      <DashboardDrawer
        menuItems={nonHiddenMenuItems}
        bottomItems={nonHiddenBottomMenuItems}
        open={openDrawer}
        setOpen={setOpenDrawer}
      />
      <Box component="main" sx={CONTENT(openDrawer, theme)}>
        <Outlet />
      </Box>
    </>
  );
}
