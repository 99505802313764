import {
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Stack,
  SxProps,
  Tooltip,
  Typography,
  capitalize,
} from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { DocumentText } from '../../assets/svgs/icons/DocumentText';
import {
  ApplicationContext,
  ApplicationContextProps,
} from '../../contexts/Application';
import { formatDuration } from '../../utils';
import { track } from '../../utils/analytics';
import ScanReportDialog from '../Common/ScanReportDialog';
import CustomCard from '../CustomCard/CustomCard';
import HistoryScanIntegrationIcon from '../ScansHistory/HistoryScanIntegrationIcon';

const CARD: SxProps = {
  pr: 4,
  pl: 4,
  pt: 4,
  pb: 4,
  height: 278,
};

const FULL_STACK: SxProps = {
  width: '100%',
};

const LABEL: SxProps = {
  color: '#7B7B88',
  fontSize: 14,
  textAlign: 'start',
  whiteSpace: 'nowrap',
};

const VALUE: SxProps = {
  color: 'black',
  fontSize: 14,
  textAlign: 'end',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export default function ApplicationScanOverviewCard() {
  const { latestScans, isLoadingLatestScans } = useContext(
    ApplicationContext,
  ) as ApplicationContextProps;
  const [selectedScanId, setSelectedScanId] = useState<string>();
  const [scanReportDialogOpen, setScanReportDialogOpen] = useState(false);
  const selectedScan = useMemo(
    () => latestScans?.find((s) => s.scan_id === selectedScanId),
    [selectedScanId, latestScans],
  );

  useEffect(() => {
    setSelectedScanId(latestScans?.[0]?.scan_id);
  }, [latestScans]);

  return (
    <>
      <ScanReportDialog
        scanId={selectedScanId}
        open={scanReportDialogOpen}
        setOpen={setScanReportDialogOpen}
      />
      <CustomCard sx={CARD}>
        {!isLoadingLatestScans && latestScans?.length ? (
          <Stack
            direction={'column'}
            justifyContent={'start'}
            spacing={1}
            sx={FULL_STACK}
          >
            <Stack direction="row" gap={1}>
              <Select
                size="small"
                value={selectedScanId || ''}
                fullWidth
                onChange={(s) =>
                  s.target.value ? setSelectedScanId(s.target.value) : undefined
                }
                sx={{ textAlign: 'start' }}
              >
                <MenuItem disabled>Select scan</MenuItem>
                {latestScans.map((scan, i) => (
                  <MenuItem key={scan.scan_id} value={scan.scan_id}>
                    {i === 0 ? 'Last scan' : scan.scan_time}
                  </MenuItem>
                ))}
              </Select>
              <Tooltip title="Download Report" sx={{ mr: -1 }}>
                <IconButton
                  disabled={!selectedScanId}
                  onClick={() => {
                    track(
                      'web_app_application_screen_scan_card_view_report_button_click',
                      {
                        scan_id: selectedScanId,
                      },
                    );
                    setScanReportDialogOpen(true);
                  }}
                >
                  <DocumentText size={24} />
                </IconButton>
              </Tooltip>
            </Stack>
            <Box></Box>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={2}
            >
              <Typography sx={LABEL}>Status</Typography>
              {selectedScan ? (
                <Typography sx={VALUE}>{selectedScan.scan_status}</Typography>
              ) : (
                <></>
              )}
            </Stack>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={2}
              spacing={4}
            >
              <Typography sx={LABEL}>Integration</Typography>
              {selectedScan ? (
                <Stack direction={'row'} alignItems={'center'} gap={0.5}>
                  <Typography sx={VALUE}>
                    {capitalize(selectedScan.source.integraion)}
                  </Typography>
                  <HistoryScanIntegrationIcon
                    type={selectedScan.source.integraion as any}
                  />
                </Stack>
              ) : (
                <></>
              )}
            </Stack>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={2}
            >
              <Typography sx={LABEL}>Test name</Typography>
              {selectedScan ? (
                <Tooltip title={selectedScan.functional_test_name}>
                  <Typography sx={VALUE}>
                    {selectedScan.functional_test_name}
                  </Typography>
                </Tooltip>
              ) : (
                <></>
              )}{' '}
            </Stack>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={2}
            >
              <Typography sx={LABEL}>Time</Typography>
              {selectedScan ? (
                <Typography sx={VALUE}>
                  {new Date(selectedScan.scan_time + 'Z').toLocaleString(
                    undefined,
                    {
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                    },
                  )}
                </Typography>
              ) : (
                <></>
              )}{' '}
            </Stack>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={2}
            >
              <Typography sx={LABEL}>User</Typography>
              {selectedScan ? (
                <Typography sx={VALUE}>{selectedScan.first_name}</Typography>
              ) : (
                <></>
              )}
            </Stack>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={2}
            >
              <Typography sx={LABEL}>Scan duration</Typography>
              {selectedScan ? (
                <Typography sx={VALUE}>
                  {formatDuration(Math.floor(selectedScan.scan_duration / 1e9))}
                </Typography>
              ) : (
                <></>
              )}
            </Stack>
          </Stack>
        ) : (
          <></>
        )}
        {isLoadingLatestScans ? (
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            height={'100%'}
          >
            <CircularProgress />
          </Stack>
        ) : (
          <></>
        )}
        {!isLoadingLatestScans && latestScans?.length === 0 ? (
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            height={'100%'}
          >
            <Typography>No Scans</Typography>
          </Stack>
        ) : (
          <></>
        )}
        {!isLoadingLatestScans && !latestScans ? (
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            height={'100%'}
          >
            <Typography color={'red'}>
              There was an error loading the latest scans
            </Typography>
          </Stack>
        ) : (
          <></>
        )}
      </CustomCard>
    </>
  );
}
