import { SxProps, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { Theme } from '@nivo/core';
import { ReactNode, useMemo } from 'react';
import { AppsIcon } from '../../assets/svgs/icons/AppsIcon';
import { CatalogIcon } from '../../assets/svgs/icons/CatalogIcon';
import { OverviewIcon } from '../../assets/svgs/icons/OverviewIcon';
import { ScanIcon } from '../../assets/svgs/icons/ScanIcon';
import { SettingsIcon } from '../../assets/svgs/icons/SettingsIcon';
import DashboardDrawer, { miniDrawerWidth } from './DashboardDrawer';

const CONTENT = (open: boolean, theme: Theme | any): SxProps => ({
  bgcolor: 'background.default',
  p: 3,
  width: `calc(100% - ${open ? miniDrawerWidth : miniDrawerWidth}px)`,
  ml: `${open ? miniDrawerWidth : miniDrawerWidth}px`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

export interface MenuItem {
  id: string;
  name: string;
  path: string;
  icon: ReactNode;
  actions?: ReactNode;
  hide?: boolean;
  active?: boolean;
}

export default function SimulatedDashboardLayout() {
  const theme = useTheme();

  const menuItems: MenuItem[] = useMemo(() => {
    return [
      {
        id: 'overview',
        name: 'Dashboard',
        path: '/expired',
        icon: <OverviewIcon />,
        active: true,
      },
      {
        id: 'applications',
        name: 'Applications',
        path: '/expired',
        icon: <AppsIcon />,
      },
      {
        id: 'api-catalog',
        name: 'APIs',
        path: '/expired',
        icon: <CatalogIcon />,
      },
      {
        id: 'scans-history',
        name: 'Scans History',
        path: '/expired',
        icon: <ScanIcon />,
      },
    ];
  }, []);

  const bottomMenuItems: MenuItem[] = useMemo(() => {
    return [
      {
        id: 'settings',
        name: 'Settings',
        path: '/expired',
        icon: <SettingsIcon />,
      },
    ];
  }, []);

  const nonHiddenMenuItems: MenuItem[] = useMemo(
    () => menuItems.filter((a) => !a.hide),
    [menuItems],
  );

  const nonHiddenBottomMenuItems: MenuItem[] = useMemo(
    () => bottomMenuItems.filter((a) => !a.hide),
    [bottomMenuItems],
  );

  return (
    <>
      <DashboardDrawer
        menuItems={nonHiddenMenuItems}
        bottomItems={nonHiddenBottomMenuItems}
        open={true}
        setOpen={() => {}}
      />
      <Box component="main" sx={CONTENT(true, theme)}></Box>
    </>
  );
}
