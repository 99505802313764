import { Vulnerability } from '../models/vulnerability';
import { PyntFilter } from './ApplicationsService';
import { get, patch, post, put } from './BaseService';

export async function getVulnerabilitiesByAppId(
  appId?: string,
): Promise<Vulnerability[]> {
  const res = await get('/vulnerability', {
    params: { application_id: appId },
  });

  // Hide vulnerabilities without application_id
  const vuls =
    res.data.vulnerabilities?.filter((vul: Vulnerability) => {
      return !!vul.application_id;
    }) || [];

  return vuls;
}

export async function getVulnerabilities(filter: PyntFilter = {}) {
  const res = await get('/vulnerability', {
    params: { filter: JSON.stringify(filter) },
  });

  if (res.data.vulnerabilities) {
    return res.data.vulnerabilities;
  }
  return res.data;
}

export async function getVulnerabilitiesCount(where: PyntFilter['where'] = {}) {
  const res = await get('/vulnerability/count', {
    params: { where: JSON.stringify(where) },
  });

  return res.data;
}

export async function getVulnerabilityById(id: string) {
  const res = await get('/vulnerability?' + 'vulnerability_id=' + id);

  const vulnerability = res.data?.vulnerabilities?.[0];

  if (!vulnerability) {
    throw new Error('Vulnerability not found');
  }

  return vulnerability;
}

export async function updateVulneravilityFalsePositiveStatus(
  vulnerabilityId: string,
  falsePositive: boolean,
  reason?: string,
) {
  const res = await patch(
    '/vulnerability?' + 'vulnerability_id=' + vulnerabilityId,
    {
      false_positive: falsePositive,
      reason,
    },
  );

  return res.data;
}

export async function updateVulneravilityTags(
  vulnerabilityId: string,
  tags: string[],
) {
  const res = await put('/vulnerability/' + vulnerabilityId + '/tags', tags);

  return res.data;
}

export async function getEvidence(scanId: string, evidenceNumber: number) {
  const res = await get(
    '/evidence?scan_id=' + scanId + '&evidence_number=' + evidenceNumber,
  );

  return res.data;
}

export async function getTicketMetadata(
  vulnerabilityId: string,
  integrationId: string,
) {
  const res = await get('/ticketmetadata', {
    params: {
      ticket_type: 'jira',
      vulnerability_id: vulnerabilityId,
      integration_id: integrationId,
    },
    timeout: 300000,
  });

  return res.data;
}

export async function createTicket(
  vulnerabilityId: string,
  integrationId: string,
  data: any,
) {
  const res = await post('/ticket', {
    ticket_type: 'jira',
    vulnerability_id: vulnerabilityId,
    integration_id: integrationId,
    project_id: data.projectId,
    issue_type_id: data.issueType,
    summary: data.name,
    description: data.description,
  });

  return res.data;
}
